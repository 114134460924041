<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <h1>{{ $t("Who are the main tenants?") }}</h1>
          <p class="fw-medium text-gray mb-3">
            {{
              $t(
                "A main tenant is someone who will appear on the tenancy agreement, such as your partner. Children living at home are not counted."
              )
            }}
          </p>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <button class="btn step__numberBtn p-0" @click="decreaseNumber">
                <i class="fa-solid fa-minus"></i>
              </button>
              <input class="step__input" v-model="numberOfPeople" type="text" />
              <button class="btn step__numberBtn p-0" @click="increaseNumber">
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>

            <strong class="ml-3 text-black">{{ $t("Main tenants") }}</strong>
          </div>

          <button
            @click="onSubmit"
            class="btn btn-primary login__btn step__submitBtn w-100 d-block"
          >
            {{ $t("Next step") }}
          </button>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection :img="img" />
    </template>
  </AuthLayoutContent>
</template>

<script>
// import Card from "@/components/general/Card.vue";
// import CardHeading from "@/components/general/CardHeading.vue";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import { mapActions } from "vuex";
export default {
  components: {
    RightSection,
    AuthLayoutContent,
  },
  data() {
    return {
      numberOfPeople: 1,
      img: require("../../../assets/images/payment/payment-step-2.svg"),
    };
  },
  computed: {},
  methods: {
    ...mapActions("user", ["updateUser"]),
    ...mapActions(["setNumberOfTennants"]),
    increaseNumber() {
      if (this.numberOfPeople == 4) {
        return;
      }
      this.numberOfPeople++;
    },
    decreaseNumber() {
      if (this.numberOfPeople <= 0) return;
      if (this.numberOfPeople > 1) {
        this.numberOfPeople--;
      }
    },
    async onSubmit() {
      this.setNumberOfTennants(this.numberOfPeople);
      await this.updateUser({ body: { totalPersons: this.numberOfPeople } });
      // this.$router.push(`/user/payments/3`);
      this.$router.replace(`/user/profile`);
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__input {
    width: 40px;
    height: 45px;
    background: #ffffff;
    border: 1px solid $border-color;
    border-radius: 5px;
    @include font(1.125rem, $gray4, 700);
    margin: 0 18px;
    text-align: center;
  }

  &__input:focus-visible,
  &__input:focus {
    // border-color: $primary;
    outline: $primary auto 1px;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__label {
    padding-left: 29px;
    @include font(1.125rem, #000, 500);

    @include xs {
      padding-left: 10px;
    }
  }

  &__submitBtn {
    margin-top: 41px;
    box-shadow: 0px 4px 7px rgba(0, 129, 31, 0.45);
  }

  &__numberBtn {
    background: #ffffff;
    border: 1px solid $primary;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    color: $primary;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    padding: 0 !important;
  }
}

.step__numberBtn:hover {
  color: $primary;
}
</style>
